.feedback-thanks-popup-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    h1 {
      font-family: "Inter";
      font-size: 31px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin-top: 5%;
    }
    p {
      text-align: center;
      font-family: "Inter";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
  .text-area-content{
      width: 100%;
      textarea{
          border-radius: 10px;
          border: 1px solid #878787;
          width: 100%;
          padding: 20px;
      }
  }

  .animate-bottom {
    position: relative;
    animation: animatebottom 0.4s;
  }
  
  @keyframes animatebottom {
    from {
      bottom: -300px;
      opacity: 0;
    }
  
    to {
      bottom: 0;
      opacity: 1;
    }
  }
  