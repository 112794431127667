.step-button-dark {
	button {
		width: 100%;
		background: #000;
		color: #fff;
		padding: 12px 0px;
		border-radius: 100px;
		font-size: 16px;
		margin-top: 26px;
		border: none;
		&:disabled {
			background: #EEF0F1;
			cursor: not-allowed;
			color: #A4A4A4;
		}
	}
}
.step-button-blue {
	width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	button {
		width: 100%;
		background: #175FFA;
		color: #FFF;
		padding: 12px 0px;
		border-radius: 100px;
		font-size: 16px;
		margin-top: 20px;
		border: none;
	}
	button[disabled]{
		background: #EEF0F1;
		cursor: not-allowed;
		color: #A4A4A4;
	}
}
.step-button-cyan {
	width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	button {
		width: 100%;
		background: #0BC9C3;
		color: #FFF;
		padding: 12px 0px;
		border-radius: 100px;
		font-size: 16px;
		margin-top: 20px;
		border: none;
		margin-left: 20px;
		margin-right: 20px;
	}
	button[disabled]{
		background: #EEF0F1;
		cursor: not-allowed;
		color: #A4A4A4;
	}
}
.step-button-gray {
	width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	button {
		width: 100%;
		background: #EEEFF3;
		color: #000000;
		padding: 12px 0px;
		border-radius: 100px;
		font-size: 16px;
		margin-top: 20px;
		border: none;
	}
	button[disabled]{
		background: #EEF0F1;
		cursor: not-allowed;
		color: #A4A4A4;
	}
}
.button-font {
	font-size: 16px;
	font-size: 16px;
	font-weight: 500;
}
.step-button-disbled {
	button {
		width: 100%;
		background: #ebe4e4;
		color: #000000;
		padding: 12px 0px;
		border-radius: 100px;
		font-size: 16px;
		margin-top: 20px;
		opacity: 0.6;
		cursor: not-allowed;
		text-align: center;
		cursor: not-allowed;
	}
}
.loader {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: block;
	margin: 7px auto;
	position: relative;
	background: #fff;
	box-shadow: -24px 0 #fff, 24px 0 #fff;
	box-sizing: border-box;
	animation: shadowPulse 2s linear infinite;
}
.step-button-red {
	button {
		width: 100%;
		background: #B03E2B;
		color: #FFF;
		padding: 12px 0px;
		border-radius: 100px;
		font-size: 16px;
		margin-top: 20px;
		border: 1px solid #B03E2B;
	}
}
.step-button-with-border-red {
	button {
		width: 100%;
		background: white;
		border-radius: 100px;
		border: 1px solid  #B03E2B;
		color: #B03E2B;
		padding: 12px 0px;
		border-radius: 100px;
		font-family: "Work Sans";
		font-size: 15px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		margin-top: 20px;
	}
}
.step-button-social {
	button {
		width: 100%;
		background: #fff;
		color: #000;
		padding: 15px 0px;
		border-radius: 100px;
		font-size: 16px;
		margin-top: 20px;
		align-items: center;
		justify-content: center;
		border: 1px solid #878787;
	}
}
.social-icon {
	margin-right: 10px;
}
.loadingIcon {
	width: 40px;
	object-fit:cover ;
	max-height: 24px;
}

.dots-5 {
	width: 10px;
	aspect-ratio: 1;
	border-radius: 50%;
	animation: d5 1s infinite linear alternate;
  }
  @keyframes d5 {
	  0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
	  33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
	  66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
	  100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
  }
