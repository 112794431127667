.modal-body-div {
    background-color: black;
    color: white;
    padding: 0px !important;
    overflow-x: hidden;
}
.modal-close-button {
    position: absolute;
    top: 5%;
    right: 3%;
    z-index: 1;
    border: none;
    width: fit-content;
    background-color: transparent;
    color: white;
}
