.success-page-main-div {
  position: relative;
  width: 100vw;
  // max-width: 600px;
  // margin: auto;
  @media (min-width: 1440px) {
    width: 50%;
    max-width: 750px;
  }
  @media (max-width: 1024px) {
    width: 70%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
  .success-div {
    margin-top: 50px;
    padding: 20px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    @media (min-width: 1440px) {
      padding: 20px 20px 60px 20px;
    }
    .step-continue{
      width: 70%;
    }
    .close-success{
      margin-top: -440px;
      height: 140px;
      width: 140px;
      margin-right: 70%;
    }
    // .success-img {
    //     img {
    //         width: 250px;
    //         @media (min-width: 1440px) {
    //             width: 340px;

    //         }
    //         @media (max-width: 576px) {
    //             width: 140px;
    //         }
    //     }
    // }
    .booking{
        color: #000;
text-align: center;

/* Desktop UI/H1 */
font-family: "Work Sans";
font-size: 31px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 43.4px */
margin-top: 40px;


    }
    .success-text {
      color: #000;
      text-align: center;

      font-size: 31px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 43.4px */
      font-family: "Work Sans";
      font-style: normal;

      text-transform: uppercase;
      color: #212121;
      @media (min-width: 1440px) {
        font-size: 42px;
      }
      @media (max-width: 576px) {
        font-size: 18px;
      }
    }
    button {
        display: flex;
        padding: 14px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
background: var(--New-Theme-Color, #1658F7);
      /* identical to box height, or 133% */

      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      border: none;
      padding: 6px 30px;
   
      color: #ffffff;
      @media (max-width: 576px) {
        font-size: 18px;
      }
    }
    .consent{
      margin-top: 40px;
    }
    .btn-booking{
        border-radius: 100px;
        background: var(--5-button-image, #EEF0F1);
        color: var(--8-black, #000);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* Desktop UI/Body 15px */
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
margin-top: 20px;
line-height: 150%; /* 22.5px */
    }
    .cal-text{
        color: var(--Blue, var(--New-Theme-Color, #1658F7));
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
margin-left: 8px;

/* Desktop UI/Body Bold */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
    .google-calendar{
      margin-top: 40px;
        display: flex;
        justify-content: space-evenly;
    }
  }
}
.confirmation{
  color: var(--7-text-icon, #373C35);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
margin-top: 20px;

/* Desktop UI/Body 15px */
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 22.5px */
}



.success-page-conatiner{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  h1{
    font-family: "Inter";
font-size: 31px;
font-style: normal;
font-weight: 500;
line-height: 140%;
margin-top: 50px;
text-align: center;
  }
  p{
    color: #373C35;
    font-family: "Inter";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 150%;
margin-bottom: 20px;
text-align: center;
  }
  h2{
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 20px;
  }
  b{
    font-family: "Inter";
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
margin-top: 20px;
  }
  h3{
    color:#C83A25;
    font-family: "Inter";
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
margin-top: 20px;
  }

}