// $background-color:rgba(0, 0, 0, 0.9);
// $background-color: #f282a5;

//
.header-div {
  // background-color: $background-color;
  width: 100%;
  position: absolute;
  z-index: 99999;
  padding: 10px 5px !important;
  transition: top 3s ease-in-out;
  top: 0;
  left: 0;
  z-index: 999;
  .header-outer-box {
    height: 69px !important;
  }
  .custom-togller {
    &:focus {
      box-shadow: none !important;
    }

    &::after {
      display: none;
    }
  }
  &.treatment-details {
    // background: $background-color;
    position: relative;
    top: 0;
  }
  &.extra {
    // background-color: $background-color;
    width: 100%;
    // position: sticky;
    // top: 0;
    // left: 0;
    // transform: translateY(0%);
    position: fixed;
    backdrop-filter: blur(50px);

    animation: headerScroll 0.5s ease-in-out;
    // overflow-x: hidden;
    // height: 100px;

    .navbar-nav {
      .navpading {
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        padding: 0 0 !important;
      }
    }
    .icon-div {
      // p {
      //     font-size: 14px;
      // }
      .social-media-icon {
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
    .header-logo {
      .header-image {
        .headerlogo {
          height: 75px;
          // width: auto;
          // @media (max-width: 834px) {
          //     width: 200px;
          //     height: 60px;
          // }
          // @media (max-width: 576px) {
          //     width: 160px;
          //     height: 40px;
          // }
        }
      }
    }
  }

  .fb-insta-div {
    width: fit-content;
    // margin: auto;
  }

  .header-logo {
    .header-image {
      text-align: center !important;
      .headerlogo {
        // transition: height 0.2s ease;
        // // transition: width 0.1s ease;
        // -webkit-transition: height 0.2s ease;
        // -moz-transition: height 0.2s ease;
        width: 75px;
        height: 75px;
        border-radius: 100px;
        object-fit: cover;
        // @media (max-width: 767px) {
        //   width: 50px;
        //   height: 50px;
        // }
        // @media (max-width: 834px) {
        //     width: 200px;
        //     height: 60px;
        // }
        // @media (max-width: 576px) {
        //     width: 160px;
        //     height: 40px;
        // }
      }

      img {
        width: 25px;
        height: 25px;
      }

      &.extra {
        // height: 65px;
        // overflow: hidden;

        @media (max-width: 834px) {
          height: 100%;
        }
        @media (max-width: 576px) {
          height: 100%;
        }
      }

      .header-svg {
        width: 280px;

        @media (max-width: 768px) {
          height: 100px;
          width: 200px;
          margin-left: 10px;
        }

        @media (max-width: 576px) {
          height: 52px;
          width: 150px;
          margin-left: 10px;
        }
      }

      .headerHamburger {
        position: absolute;
        width: 200px;
        margin: auto;
        // text-align: center;
        top: 30px !important;
        left: -85px !important;
        // background: $background-color;
        box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.25);
        text-align: center;
        color: #ffffff !important;
        border-radius: 0;

        a {
          color: #ffffff !important;
          cursor: pointer;
        }

        a:hover {
          color: #80808061 !important;
        }

        button {
          width: fit-content;
          margin: auto;
          background-color: #80808061;
          color: #ffffff !important;
          font-weight: 500;

          &:hover {
            background-color: #03827e;
          }
        }

        .student-hr {
          background-color: #ffffff;
          height: 2px !important;
        }

        .avatar-div {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          cursor: pointer;
          .avatar-image {
            img {
              border: 2px solid #80808061;
              border-radius: 50%;
              width: 35px;
              height: 35px;
              object-fit: cover;
            }
          }

          .avatar-name {
            font-family: "Avenir Next";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;

            color: #80808061;
          }
        }

        .avatar-links {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          margin: 10px 0;

          &.my-portal:hover {
            svg {
              path {
                // stroke-width: 2.5;
              }
            }

            color: #80808061;
          }

          &.edit-profile:hover {
            svg {
              path {
                // stroke-width: 2.5;
              }
            }

            color: #80808061;
          }

          &.logout:hover {
            // font-weight: 700;

            svg {
              path {
                // stroke-width: 2.5;
              }
            }
          }

          &.logout {
            color: #ff7e7e;

            svg {
              path {
                // stroke: #ff7e7e !important;
              }
            }
          }
        }
      }
    }
  }

  ul {
    .contentWrapper {
      align-items: baseline;
    }
  }

  .icon-div {
    // width: 90px;
    p {
      color: #fff;
      margin: 0;
      text-align: center;
    }

    hr {
      margin: 5px 0;
      opacity: 1;
      color: #fff;
    }

    .iconsWrapper {
      justify-content: space-between;

      .icons {
        padding: 0 10px;

        a:hover path {
          // fill: #FFF;
        }
      }

      .call-image,
      .fb-image {
        margin-right: 15px;
      }
    }
  }

  .icon-ham-div {
    li {
      margin: 0 32px;
    }
  }
}

.offcanvas-custom {
  background-color: #000000;

  .close-icon {
    background-color: #000;
    border: none;
  }

  .header-items {
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 100%;

    .items-list {
      text-align: center;

      ul {
        list-style: none;

        li {
          width: 50%;
          margin: 10px auto;

          @media (max-width: 576px) {
            margin: 3px auto;
          }

          a {
            padding: 8px 0;

            &:hover {
              background-color: #80808061;
            }

            @media (max-width: 576px) {
              padding: 3px 0;
            }
          }

          button {
            width: fit-content;
            margin: auto;
            background-color: #80808061;
            padding: 5px;
            color: #ffffff !important;
            font-weight: 500;
          }
        }

        .avatar-div {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin-top: 40px;
          cursor: pointer;
          @media (max-width: 576px) {
            margin-top: 28px;
          }

          @media (max-width: 320px) {
            margin-top: 0;
          }

          .avatar-image {
            img {
              border: 2px solid #80808061;
              border-radius: 50%;
              width: 35px;
              height: 35px;
              object-fit: cover;

              @media (max-width: 768px) {
                width: 70px;
                height: 70px;
              }
            }
          }

          .avatar-name {
            font-family: "Avenir Next";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;

            color: #80808061;
            margin-top: 15px;
          }
        }

        .avatar-links {
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          // margin: 10px 0;
          .arraow-image {
            display: none;
          }

          li {
            padding: 8px 0;

            &:hover {
              background-color: #80808061;
            }

            @media (max-width: 576px) {
              padding: 3px 0;
            }
          }

          &.my-portal:hover {
            svg {
              path {
                stroke-width: 2.5;
              }
            }

            color: #fff;
          }

          &.edit-profile:hover {
            svg {
              path {
                stroke-width: 2.5;
              }
            }

            color: #fff;
          }

          &.logout:hover {
            font-weight: 700;

            svg {
              path {
                stroke-width: 2.5;
              }
            }
          }

          &.logout {
            color: #ff7e7e;

            svg {
              path {
                stroke: #ff7e7e !important;
              }
            }
          }
        }
      }
    }
    position: relative;
    .social-media-icons {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 300px;
      transform: translateX(-50%);
      @media (max-width: 834px) {
        margin-bottom: 20px;
      }

      .mobile-header {
        a {
          margin: 0 7px;
        }
      }
    }
  }
}

.dropdown-item:hover {
  background-color: #000000;
  // font-weight: 700;
}

body {
  -webkit-tap-highlight-color: transparent;
}

.header-div {
  // #menu_checkbox {
  //     display: none;
  // }

  label {
    // position: absolute;
    // top: 50%;
    // right: 0;
    // left: 0;
    // margin: auto;
    // width: 45px;
    // height: 45px;
    // margin: -30px auto 0 auto;
    // cursor: pointer;
  }

  // label:before {
  //     content: "";
  //     position: fixed;
  //     top: 0;
  //     right: 0;
  //     bottom: 0;
  //     left: 0;
  //     background-color: #f44336;
  //     transition: 1.2s cubic-bezier(0, 0.96, 1, 0.02) background-color;
  // }

  label div {
    position: relative;
    top: 0;
    height: 5px;
    background-color: #fff;
    margin-bottom: 8px;
    transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
      0.3s ease right;
    border-radius: 2px;
  }

  label div:first-child {
    transform-origin: 0;
  }

  label div:last-child {
    margin-bottom: 0;
    transform-origin: 60px;
  }

  label div:nth-child(2) {
    right: 0;
    width: 45px;
  }

  #menu_checkbox:checked + label div:first-child {
    top: -6px;
    width: 40px;
    transform: rotateZ(45deg);
  }

  #menu_checkbox:checked + label div:last-child {
    top: 28px;
    width: 36px;
    transform: rotateZ(45deg);
  }

  #menu_checkbox:checked + label div:nth-child(2) {
    width: 60px;
    top: 0;
    right: 13px;
    transform: rotateZ(-45deg);
  }
}

.offcanvas-body {
  ul {
    padding-left: 0;
  }

  @media (max-width: 320px) {
    padding: 0;
  }
}

.vertical-divider {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    border-bottom: 2px solid #fff;
    bottom: -20px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);

    @media (max-width: 320px) {
      bottom: -13px;
    }
  }
}

.offcanvas-header {
  @media (max-width: 320px) {
    padding-bottom: 0;
  }
}

.closeIcon {
  .dropdown {
    .btn {
      border: none !important;
      color: none !important;
      background-color: none !important;

      &:first-child:active {
        border: none !important;
        color: none !important;
        background-color: none !important;
      }
    }
    .dropdown-toggle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .line-container {
        padding: 5px 0;
        .line {
          height: 2px;
          width: 25px;
          background: #fff;
          margin-top: 7px;
          transition: all 0.4s ease;
        }
        .line:first-child {
          margin-top: 0;
        }
      }
    }
  }
  .dropdown {
    &.show {
      .dropdown-toggle {
        .line-container {
          // transition: all 4s ease;

          .line:first-child {
            transform: rotate(45deg);
            position: relative;
            top: 9px;
          }
          .line:nth-child(2) {
            opacity: 0;
            transition: opacity 0.4s ease;
            // position: absolute;
            position: relative;
            animation: leftMove 0.3s infinite;
            left: -20px;
            animation-iteration-count: initial;
          }
          .line:last-child {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            position: relative;
            top: -9px;
          }
        }
      }
    }
  }
}

@keyframes headerScroll {
  from {
    transform: translateY(-100%);
    // transition: transform 0.3s ease-in-out;
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes leftMove {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    left: -20px;
    opacity: 0;
    display: none;
  }
}
.cart2-margin {
  @media (max-width: 834px) {
    margin-left: 30px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  @media (max-width: 576px) {
    margin-left: 0 !important;
  }
}
.cart-dropdown-div {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 10px 0;
  margin-left: 25px;
  cursor: pointer;

  @media (max-width: 834px) {
    justify-content: center;
    margin-left: 0;
  }

  .cart-dot-div {
    position: relative !important;
    &::after {
      position: absolute !important;
      content: "";
      top: 0;
      left: 20px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #ff0000 !important;
    }
  }
  span {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #fff;
  }
}
.social-media-icon {
  i {
    font-size: 25px;
  }
}
.insta-image {
  i {
    font-size: 25px;
  }
}

@media (max-width: 992px) {
  .banner {
    margin-top: 89px !important;
  }
  .slick-dots{
    display: none !important; 
  }
}

@media (max-width: 767px) {
  .header-div {
    .header-outer-box{
      height: auto !important;
      .header-logo{
        .header-image{
          margin: 0px !important;
          img{
            width: 50px !important;
            height: 50px !important;
          }
        }
      }
    }
  }
}

.CustomeheaderWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .fb-insta-div.icon-div {align-items: center;display: flex;flex-direction: column;justify-content: center;}
  hr {
    width: 100%;
}
}
