$color_1: #000000;
$color_2: #5e5e5e;
$font-family_1: "Inter";
/* skeleton-loader.scss */

/* Mobile width */
.mobile-skeleton {
	/* Set your mobile-specific styles here */
	width: 100%;
  
  /* Adjust the width as needed for mobile */
  }
  
  /* Desktop width */
  .desktop-skeleton {
	/* Set your desktop-specific styles here */
	width: 500px; /* Adjust the width as needed for desktop */
  }

  /* Modal width */
  .modal-skeleton {
    /* Set your modal-specific styles here */
    width: 400px; /* Adjust the width as needed for modal */
    }
    
  .color-picker{
    margin-bottom: 68px;
  }
  
.clinics-skeleton-container {
  display: flex;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  p {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $color_1;
  }
  span {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $color_2;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
  }
}
.clinics-skeleton-image-container {
  height: 40px;
  width: 40px;
  display: flex;
  position: relative;
  padding: 20px;
  padding-bottom: 0;
  border-radius: 50%;
  background: #eef0f1;
}
$color_1: #000000;
$color_2: #5e5e5e;
$font-family_1: "Inter";

.clinics-skeleton-container {
  display: flex;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  p {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $color_1;
  }
  span {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $color_2;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
  }
}
.clinics-skeleton-image-container {
  height: 40px;
  width: 40px;
  display: flex;
  position: relative;
  padding: 20px;
  padding-bottom: 0;
  border-radius: 50%;
  background: #eef0f1;
}

.calendar-skeleton {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
}

.skeleton-day {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #f0f0f0; /* Adjust the background color */
}

.appointment-skeleton {
  box-shadow: 0px 0px 0px 1px #ddd inset;
  border-radius: 20px;
  padding: 20px;
}

.calendar-skeleton {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
}

.skeleton-day {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #f0f0f0; /* Adjust the background color */
}

.appointment-header-skeleton {
  background: #f9f9f9;
  padding-bottom: 65px;
  .close-back {
    justify-content: space-between !important;
    // padding: 20px 10px !important;
    background: #f9f9f9;
    width: 100%;
    padding: 24.5px 165px;
    button {
      padding: 14px 24px;
      border: none;
      border-radius: 100px;
    }
  }
}
.s1 {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 60px;
  .content1 {
    width: 50%;
    .general-details {
      padding-top: 22px;
      padding-bottom: 60px;
      span {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      p {
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #373c35;
      }
      .user-image-container {
      }
    }
  }
}

.skeleton-container {
  background: linear-gradient(to right, #ffffff 0%, #e0e0e0a4 50%, #ffffff 100%);

  background-size: 200% 100%;
  animation: skeleton-animation 1.5s linear infinite;
}
@keyframes skeleton-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.calendar-skeleton-wrapper {
  // border: 1px solid #e0e0e06e;
  // padding: 10px;
  border-radius: 10px;
}
.custom-calendar-skeleton {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: scroll;
  height: calc(100vh);
  position: relative;
  // top: 8rem;

  .date-items {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    @extend .skeleton-container;
    margin: 10px;
  }

  .appointment-skeleton{
    box-shadow: 0px 0px 0px 1px #ddd inset;
    border-radius: 20px;
    padding: 20px;
  }

  .appointment-header-skeleton{
    background: #f9f9f9;
	  padding-bottom: 65px;
    .close-back{
      justify-content: space-between !important;
      // padding: 20px 10px !important;
      background: #f9f9f9;
      width: 100%;
      padding: 24.5px 165px;
      button{
          padding: 14px 24px;
          border: none;
          border-radius: 100px;
      }
    }

  }
  .s1{
	width: 100%;
	margin-top: 30px;
	margin-bottom: 60px;
	.content1{
		width: 50%;
		.general-details{
            padding-top: 22px;
            padding-bottom: 60px;
            span{
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            p{
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                color: #373C35;
            }
            .user-image-container{

            }
        }
	}
  }
}
