// $landing-footer-bg: #222222;;
$landing-footer-bg: #FFF;


.landing-page-div {
    .image-container {
        .bg-image {
            width: 100%;
            height: 90vh;
            background-color: black;
            @media (max-width: 840px) {
                height: 73vh;
            }
            @media (max-width: 576px) {
                height: 90vh;
            }
            @media (max-width: 320px) {
                height: 100vh;
            }
            .image {
                background-image: url("../../assets/images/landing-page/landingpage.jpg");

                /* Full height */
                height: 100%;

                /* Center and scale the image nicely */
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                @media (max-width: 576px) {
                    background-position: 70%;
                }
            }
        }
        .image-div-content {
            width: 100%;
            overflow: hidden;
            position: absolute;
            top: 28%;

            @media (min-width: 1200px) {
                top: 50%;
                transform: translateY(-50%);
            }
            @media (max-width: 768px) {
                top: 20%;
            }

            .title {
                h1 {
                    font-family: "Work Sans";
                    font-style: normal;
                    font-weight: 400;
                    // font-size: 63px;
                    font-size: 55px;
                    line-height: 70px;
                    color: #ffffff;
                    @media (min-width: 1440px) {
                        font-size: 62px;
                        line-height: 72px;
                    }
                    @media (max-width: 576px) {
                        font-size: 26px;
                        line-height: 40px;
                        text-align: center;
                    }
                }
            }

            .landing-button {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                // margin-bottom: 40px;
                @media (min-width: 1440px) {
                    margin-top: 50px;
                }
                @media (max-width: 576px) {
                    flex-direction: column;
                }
                button {
                    backdrop-filter: blur(30px) !important;
                    border-radius: 10px;
                    border: none;
                    outline: none;
                    width: 43%;
                    color: #ffffff;
                    font-family: "Avenir Next";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    background: rgba(255, 255, 255, 0.3);
                    &:hover {
                        background: #07ccbd;
                    }
                    @media (min-width: 1440px) {
                        font-size: 48px;
                        line-height: 65px;
                        padding: 20px 0;
                    }
                    @media (max-width: 576px) {
                        width: 80%;
                        font-size: 28px;
                        margin-bottom: 15px;
                    }
                }
            }

            .skynews-div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 0 30px;
                @media (max-width: 320px) {
                    margin-top: 5px !important;
                }
                span {
                    font-family: "Avenir Next";
                    font-style: italic;
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 50px;
                    color: #ffffff;

                    @media (min-width: 1440px) {
                        // margin-top: 20px;
                        font-size: 42px;
                        line-height: 57px;
                    }
                    @media (max-width: 576px) {
                        font-size: 24px;
                    }

                    @media (max-width: 320px) {
                        font-size: 20px;
                    }
                }

                .sky-news-image {
                    text-align: center;

                    img {
                        width: 150px;
                        height: auto;
                        @media (min-width: 1440px) {
                            width: 342px;
                            height: 90px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

    .landing-footer-div {
        overflow: hidden;
        background-color: #FFF;
        // height: calc(100vh - 70vh);
        padding: 30px 20px;
        margin-top: -1px;
        .google-image-div {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 576px) {
                display: block;
            }
            .google-image {
                // @media (max-width: 576px) {
                //     width: 100%;
                // }
                @media (max-width: 575px){
                    max-width: 60px;
                }

                img {
                    // width: 60%;

                    // @media (min-width: 1440px) {
                    //     width: 100%;
                    //     height: 100%;
                    // }
                    @media (max-width: 576px) {
                        display: block;
                        height: 50px;
                        margin-left: auto;
                        margin-right: auto;
                        width: 100%;
                    }
                }
            }

            .ratings-div {
                // padding: 20px;
                @media (min-width: 1440px) {
                    svg {
                        height: 33px;
                        width: 33px;
                    }
                }
            }

            span {
                font-family: "Avenir Next";
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 38px;
                /* identical to box height */
                color: #000;

                @media (min-width: 1440px) {
                    font-size: 28px;
                    margin-top: 5px;
                }

                @media (max-width: 576px) {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .display-total-reviews{
                width: 75px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                border-radius: 20px;
                background: var(--5-button-image, #EEEFF3);
                margin: 5px 0px;
                img{
                    width: 18px;
                    height: 18px;
                    margin: 0;
                    margin-top: -5px;
                }
                .rating-number, .rating-people{
                    margin: 0;
                    color: #000;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: "Work Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    padding: 0;
                }
                @media (max-width: 576px) {
                    margin: 5px auto;
                }
            }
        }
    }
    .slick-slider {
        .slick-prev:before {
            background-color: #626262;
            // filter: drop-shadow(0px 14px 24px rgba(0, 0, 0, 0.25));
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            padding: 2px 12px;
            background-image: url(../../assets/images/dashboard/slickArrow.svg);
            background-size: 6px;
            background-repeat: no-repeat;
            background-position: center;
            @media (max-width: 576px) {
                display: none;
            }
        }
        .slick-next:before {
            background-color: #626262;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            padding: 2px 12px;
            background-size: 6px;
            background-image: url(../../assets/images/dashboard/slickArrow.svg);
            background-repeat: no-repeat;
            background-position: center;
            @media (max-width: 576px) {
                display: none;
            }
        }
        .slick-arrow {
            filter: drop-shadow(0px 14px 24px rgba(0, 0, 0, 0.25));
        }
        .slick-prev {
            transform: rotate(180deg);
            left: -18px;
        }
        .slick-prev {
            margin-top: -10px !important;
        }
        .slick-next {
            margin-top: 2px !important;
            right: -18px;
        }
    }
}

.carouselWrapper {
    h4.name {
        font-size: 25px !important;
        margin: 0px !important;
    }
    p.sub-title {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    span.profession {
        font-size: 16px !important;
    }
}

.review-hr {
    position: relative;
    // &::after {
    //     position: absolute;
    //     content: "";
    //     width: 90%;
    //     border-bottom: 2px solid #a6a6a6;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     bottom: 0;
    // }
}

@media (max-width: 575px){
    .reviewMainSec{
        .landing-footer-div{
            .google-image-div{
                span .star-svg{
                    height: 12px !important;
                    width: 12px !important;
                }
            } 
        } 
    } 
    .custom-review-box{
        .box-1{
                padding: 0 0 0 10px;
                .style-module_starRatingWrap__q-lJC{
                    justify-content: start !important;
                }
                .display-total-reviews{
                    margin: 0 !important;
                    line-height: 23px !important;
                    .star{
                        img{
                            width: 12px !important;
                            margin: 0px !important;
                        }
                    }
                    .rating-number{
                        font-size: 12px !important;
                    }
                    .rating-people{
                        font-size: 12px !important;
                    }
                }
                .style-module_starRatingWrap__q-lJC ~ span{
                    font-size: 12px !important;
                }
        }
        .box-2{
                padding: 0 !important;
            .slider-box{
                padding: 0 5px !important;
                .rating-card-div{
                        margin: 0 5px !important;
                        padding: 6px !important;
                    .profile-image{
                        .avtar-image{
                                width: 30px !important;
                                height: 30px !important;
                            span{
                                font-size: 12px !important;
                            }
                        }
                    } 
                    .user-name{
                        .use-title{
                            font-size: 11px;
                        }     
                        .use-date{
                            font-size: 11px;
                            margin-bottom: 0;
                        }
                    }
                }
                .star-svg{
                    width: 14px;
                }
                .content{
                    p{
                        height: auto !important;
                        font-size: 12px;
                    }
                }
                .text-end{
                    .view-moreBtn{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.slider-box{
    padding: 0 1rem;
    @media (max-width: 1200px) and (min-width: 768px){
        padding: 0 3px !important;
    }
    @media (max-width: 767px){
        padding: 0 10px !important;
    }
}