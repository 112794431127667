.carsausel-customdiv{

    .auth-carousel-main-div {
        width: 100%;
        .bg-image {
            position: relative;
            .image {
                height: 100vh;
                box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                // filter: grayscale(100%);
            }
            .image-upper-content {
                position: absolute;
                left: 10%;
                bottom: 5%;
                .about-text {
                    font-family: "Work Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 44px;
                    /* or 138% */
    
                    color: #ffffff;
    
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    font-family: "Work Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 44px;
                    /* or 138% */
                    color: #ffffff;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    @media (max-width: 1024px) {
                        font-size: 22px;
                        line-height: 26px;
                    }
                    @media (max-width: 576px) {
                        font-size: 16px;
                        line-height: 18px;
                    }
                    @media (max-width: 320px) {
                        font-size: 14px;
                        line-height: 15px;
                    }
                }
                .author {
                    font-family: "Work Sans";
                    font-style: italic;
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 44px;
                    /* identical to box height, or 138% */
    
                    color: #ffffff;
    
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    @media (max-width: 768px) {
                        font-size: 26px;
                        line-height: 18px;
                    }
                    @media (max-width: 576px) {
                        font-size: 18px;
                        line-height: 18px;
                    }
                    @media (max-width: 320px) {
                        font-size: 14px;
                        line-height: 15px;
                    }
                }
            }
        }
        // display: none;
        
    }
    .carousel-control-next{
        display: none;
    }
    .carousel-control-prev{
        display: none;
    }
}

