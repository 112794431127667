.cancle-popup-container{
    h1{
        font-family: "Inter";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 20px;
    }
    p{
        font-family: "Inter";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 150%;
    }
}

.ReactModal__Content.ReactModal__Content--after-open.cancel-modal{
    width: 40%;
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 90% !important;
      background: #fff;
      margin: 0 auto !important;
      z-index: 1111 !important;
      border-radius: 20px !important;
      transform: none !important;
      top: 10% !important;
    }
  }
