.feedback-popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-family: "Inter";
    font-size: 31px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  p {
    text-align: center;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}
.text-area-content {
  width: 100%;
  position: relative;
  textarea {
    border-radius: 10px;
    border: 1px solid #878787;
    width: 100%;
    padding: 20px;
    height: 150px;
    resize: none;
  }
  .ai-discripion-div {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}
.feedback-modal{
  // transform: translateY(-200%);
  // transition: opacity 0.3s, transform 0.3s;
  top: 200%;
  left: 0% !important;
  right: 0;
  margin: 0 auto !important;
  // scale: .5;
}
.ReactModal__Content.ReactModal__Content--after-open.feedback-modal{
  width: 40%;
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  transform: translateY(-50%) !important;
  // transform: translateX(50%) !important;
  // transform: translateY(-50%) !important;
  transition: transform 0.6s, top 0.6s;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 90% !important;
    background: #fff;
    margin: 0 auto !important;
    z-index: 1050 !important;
    border-radius: 20px !important;
    transform: none !important;
    top: 10% !important;
  }
}
.ReactModal__Overlay--after-open{
  // transform: translateY(100%) !important;
  //   transition: transform 0.6s, opacity 200ms ease-in-out !important;
  //   top: 0% !important;
}
.CustomeFeedBackModel {
  width: 40% !important;
  .ModelIcon {
    width: 98px;
    height: 98px;
    border-radius: 20px;
    background: #EEF0F1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;
}
    h1{
      color: #000;
    }
    p{
      color: #000;
      margin-bottom: 20px;
      line-height: 24px !important;
    }
    .text-area-content{
      textarea{
        border: 1px solid #ACACAC !important;
        &::placeholder{
          color: #A6A6A6;
          font-family: "Inter";
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      }

    }
}

@media (max-width:1400px) {
  .CustomeFeedBackModel{
    padding: 20px !important;
    .ModelIcon{
      width: 80px;
      height: 80px;
      img{
        width: 40px;
      }
    }
    h1{
      font-size: 20px;
    }
    p{
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
}


@media (max-width:900px) {
  .CustomeFeedBackModel{
    padding: 20px !important;
    width: 60% !important;
  }
}
