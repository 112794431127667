.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    background: rgba(0, 0, 0, 0.76) !important;
    opacity: 1;
    position: fixed !important;
    z-index: 1150 !important;
}
.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.PatientDobWarningModalPortal .ReactModal__Overlay--after-open {
    z-index: 1150 !important;
}

// modal design

.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    padding: 20px 30px 40px;
    background: #ffffff;
    box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.25);
    width: 50%;
    max-width: 800px;

    &.bookPractical-main-div {
        padding: 20px 0 0 0;
        width: 42%;
    }
    max-height: 90vh;
    overflow-y: auto;
    // overflow: hidden;
    max-width: 800px;
    // height: 100%;
    max-height: 90vh;

    @media (max-width: 1024px) {
        width: 50%;
    }
    @media (min-width: 768px) and (max-width: 834px) {
        width: 70%;
    }
    @media (max-width: 576px) {
        width: 95%;
        padding: 15px 5px 30px;
        height: auto;
        overflow-y: auto;
    }
}
.custom-modal:focus-visible {
    outline: none;
}
.modal-body-container {
    .modal-header-div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.book-practical-header {
            margin: auto;
            text-align: center;
            width: 70%;
            .modal-title {
                margin: 0 50px;
            }
        }
        @media (max-width: 576px) {
            padding: 15px 0;
        }
        .modal-title {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: #000000;
            @media (max-width: 576px) {
                font-size: 16px;
            }
            @media (max-width: 320px) {
                font-size: 14px;
            }
            @media (max-width: 576px) {
                font-size: 16px;
            }
            @media (max-width: 320px) {
                font-size: 14px;
            }
        }
        .modal-close-icon {
            margin-top: -5px;
            border: none;
            outline: none;
            border-radius: 50%;
            background: #f6f7f8;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            // @media (max-width:576px){
            //     position: absolute;
            //     right: 24px;
            //     top: 30px;
            // }
        }
    }
}

.dynamic-content-text {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    padding: 25px 0;
    color: #000;
    @media (max-width: 576px) {
        padding: 10px 25px 25px 25px;
    }
    // text-transform: capitalize;
}

.dynamic-content-btn {
    padding: 8px 14px;
    background-color: #80808061;
    color: #fff;
    font-family: "Work Sans";
    font-weight: 600;
    width: 90px;
    border-radius: 4px;
    border: #80808061;
}

.dynamic-content-outline {
    @extend .dynamic-content-btn;
    background-color: #fff;
    color: #80808061;
    border: 1px solid #80808061;
}

.review-modal-body {
    .user-image {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
        }
        .google-icon {
            height: 30px;
            width: 30px;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #fff;
        }
    }
    .user-name {
        font-size: 26px;
        color: #000;
        font-family: "Avenir Next";
        font-weight: 600;
    }
    .review-time {
        font-size: 16px;
        font-family: "Avenir Next";
        font-weight: 600;
        color: #6d6d6d;
        text-align: center;
    }

    .review-text {
        max-height: 180px;
        overflow-y: scroll;
        p {
            font-size: 16px;
            color: #000;
            font-family: "Avenir Next";
            font-weight: 500;
            margin-top: 10px;
            line-height: 22px;
        }
    }
}
::-webkit-scrollbar {
    width: 5px;
    border-radius: 30px;
}

::-webkit-scrollbar-track {
    background: #f1f1f100;
}

::-webkit-scrollbar-thumb {
    background: #80808061;
    border-radius: 10px;
}
