@font-face {
    font-family: "Avenir Next";
    src: url("/fonts/Avenir_Next_Font.ttf") format("truetype");
}
// .body {
//     font-family: "Avenir Next", sans-serif;
// }
.cursor-pointer {
    cursor: pointer;
}
.cursor-na {
    cursor: not-allowed;
}
.for-custom-zindex {
    position: absolute;
    z-index: 9999999999 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.nodata-text {
    font-size: 22px;
    font-family: "Work Sans";
    font-weight: 600;
    text-align: center;
    &.nodata-services{
        img{
            max-width: 250px;
        }
    }
}

.order {
    width: fit-content !important;
    background: none !important;
    box-shadow: none !important;
    // pointer-events: none !important;
    &.Toastify__toast {
        // pointer-events: none;

        .order-image {
            pointer-events: all !important;
        }
    }

    button {
        visibility: hidden !important;
    }
}

.mr-5{
    margin-right: 20px;
}

.LoaderMain {
    position: fixed;
    background: #00000078;
    width: 100%;
    height: 100%;
    z-index: 1111111;
    top: 0;
}

.desc_div{
    text-align: justify;
  }