// $footer-bg: #000000;
// $footer-bg: #F282A5;



.footer-div {
    // background: $footer-bg;
    padding: 20px 0;
    .kanvas-image {
        margin: 0px 0px 15px;
        img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 100px ;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }
    .social-media-icons{
        .cursor-pointer{
            display: flex !important;
            grid-gap: 15px !important; 
            margin-bottom: 15px;
        }
    }

    .routes-div{
        display: flex;
        grid-gap: 50px;
        padding: 20px 0px 0px;
        .routes-tag{
            span{
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
   
    .copyrights{
        margin-bottom: 10px;
        span{
            font-size: 14px;
        }
    }
    .poweredby{
        span{
            font-size: 14px;
        }
    }
    // .social-media-icons {
    //     margin: 15px 0;
    //     a:hover circle {
    //         stroke: #FFF;
    //     }
    //     a:hover path {
    //         fill: #FFF;
    //     }
    //     .social-media-icon {
    //         padding: 0 10px;
    //     }
    // }
    // .routes-div {
    //     margin: 15px 0;
    //     @media (max-width: 576px) {
    //         text-align: center;
    //     }
    //     .routes-tag {
    //         span {
    //             font-family: "Avenir Next";
    //             font-style: normal;
    //             font-weight: 500;
    //             font-size: 22px;
    //             line-height: 38px;
    //             color: #ffffff;
    //             cursor: pointer;
    //             margin: 0 30px;
    //             &:hover {
    //                 color: #FFF;
    //             }
    //             @media (max-width: 768px) {
    //                 font-size: 18px;
    //             }
    //         }
    //     }
    // }
    // .hr-div {
    //     margin: 15px 0;

    //     hr {
    //         width: 80%;
    //         height: 2px;
    //         margin: auto;
    //         color: #ffffff !important;
    //     }
    // }
    // .copyrights {
    //     margin: 15px 0;

    //     span {
    //         font-family: "Avenir Next";
    //         font-style: normal;
    //         font-weight: 500;
    //         font-size: 20px;
    //         line-height: 38px;
    //         color: #ffffff;
    //         @media (max-width: 768px) {
    //             font-size: 16px;
    //         }
    //         @media (max-width: 576px) {
    //             font-size: 14px;
    //         }
    //     }
    // }
    // .poweredby {
    //     span, a {
    //         font-family: "Avenir Next";
    //         font-style: normal;
    //         font-weight: 500;
    //         font-size: 19px;
    //         line-height: 38px;
    //         color: #ffffff;
    //         text-decoration: none;
    //         @media (max-width: 768px) {
    //             font-size: 15px;
    //         }
    //         @media (max-width: 576px) {
    //             font-size: 13px;
    //         }
    //     }
    // }
}


@media (max-width: 567px) {
    .footer-div{
        .kanvas-image{
            img{
                width: 70px;
            }
        }
        .routes-div{
            grid-gap: 10px;
            .routes-tag{ 
                text-align: center;
                span{
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .kanvas-image{
        img{
            width: 50px !important;
            height: 50px !important;
        }
    }
    .social-media-icons{
        .d-flex{
            margin-bottom: 0px !important;
            .social-media-icon{
                svg{
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
    .routes-div{
        padding: 15px 0px 0px !important;
        grid-gap: 15px;
    }
}