.auth-main-div {
    height: 100%;
    background-color: #000;

    @media (max-width: 576px) {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        background-color: transparent;
    }
    .common-div {
        width: 70%;
        margin: auto;
        max-width: 600px;
        @media (min-width: 1440px) {
            width: 80%;
        }
        @media (min-width: 768px) and ((max-width: 834px)) {
            width: 90%;
        }
        @media (max-width: 576px) {
            width: 95%;
        }

        .common-form-bg-color {
            background: #333333;
            @media (max-width: 576px) {
                background: none;
            }

            .student-text {
                font-family: "Work Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                color: #ffffff;

                @media (min-width: 1440px) {
                    font-size: 62px;
                    line-height: 72px;
                }
                @media (max-width: 1024px) {
                    font-size: 32px;
                    line-height: 42px;
                }
                @media (max-width: 768px) {
                    font-size: 28px;
                    line-height: 35px;
                }
                @media (max-width: 576px) {
                    font-size: 26px;
                    line-height: 42px;
                }
                @media (max-width: 320px) {
                    font-size: 18px;
                    line-height: 18px;
                }
            }
            h5 {
                font-family: "Work Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                color: #ffffff;
                @media (min-width: 1440px) {
                    font-size: 62px;
                    line-height: 72px;
                }
                @media (max-width: 1024px) {
                    font-size: 32px;
                    line-height: 42px;
                }
                @media (max-width: 768px) {
                    font-size: 28px;
                    line-height: 35px;
                }

                @media (max-width: 576px) {
                    font-size: 26px;
                    line-height: 42px;
                }
                @media (max-width: 320px) {
                    font-size: 18px;
                    line-height: 18px;
                }
            }
            p {
                font-family: "Avenir Next";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                @media (min-width: 1440px) {
                    font-size: 22px;
                }
                @media (max-width: 1024px) {
                    font-size: 16px;
                }
                @media (max-width: 320px) {
                    font-size: 14px;
                }
                .do-not-text {
                    color: #ffffff;
                }

                .signup-text {
                    color: #80808061;
                    font-size: 18px;
                    font-weight: 700;
                    // border-bottom: 1px solid #80808061;
                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }
                    @media (max-width: 320px) {
                        font-size: 14px;
                    }
                    text-decoration: underline;
                }
            }

            .input-field-div {
                width: 100%;
                .input-field {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: start;

                    input {
                        width: 100%;
                        padding: 7px 33px 7px 20px;
                        background: #ffffff;
                        border-radius: 60px;
                        border: none;
                        @media (min-width: 1440px) {
                            padding: 18px 20px;
                        }
                        &:focus {
                            outline: none;
                        }
                        &::placeholder {
                            color: rgba(0, 0, 0, 0.6);
                            // font-weight: 500;
                        }
                    }

                    small {
                        color: rgb(158, 87, 99);
                        padding-left: 20px;
                    }

                    .eye-field {
                        position: relative;
                        width: 100%;
                    }
                    .eye {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 10px;
                        width: 20px;
                        cursor: pointer;
                        img {
                            height: 14px;
                            width: 14px;
                        }
                    }
                }
                .forgot-password {
                    font-family: "Avenir Next";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: right;
                    text-decoration-line: underline;
                    color: #80808061;
                    width: 100%;
                    display: block;
                    @media (max-width: 1024px) {
                        font-size: 14px;
                    }
                }

                .submit-button {
                    font-family: "Avenir Next";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 42px;
                    text-transform: capitalize;
                    color: #ffffff;
                    background: #80808061;
                    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.15);
                    border: none;
                    width: 100%;
                    @media (max-width: 1024px) {
                        font-size: 18px;
                    }
                    @media (max-width: 320px) {
                        margin-top: 10px !important;
                    }
                }
                button:disabled {
                    background-color: #a4a4a4;
                }

                .inputStyle {
                    width: 15% !important;
                    margin: auto;
                    height: 50px;
                    border-radius: 10px;
                    border: 0px;
                    font-size: 20px;
                    &:focus {
                        border: none;
                        box-shadow: none;
                    }
                    &:focus-visible {
                        border: none;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

//===============login===============
// .login-top {
//   margin-top: 14% !important;
// }
.login-padding {
    padding: 40px 48px;
    max-width: 600px;

    @media (min-width: 1440px) {
        padding: 60px 48px;
    }
    @media (max-width: 834px) {
        padding: 30px 12px;
        margin: 30px 0;
    }
    @media (max-width: 576px) {
        padding: 18px;
    }
    @media (max-width: 320px) {
        padding: 10px;
        margin: 0;
    }
}
//===============email-verification===============
// .email-verification-top {
//   margin-top: 18% !important;
// }
.email-verification-padding {
    padding: 40px 50px;
    @media (min-width: 1440px) {
        padding: 65px 50px;
    }
    @media (max-width: 1024px) {
        padding: 35px 28px;
    }
    @media (max-width: 768px) {
        padding: 35px 18px;
    }
    @media (max-width: 576px) {
        margin: 30px 0;
        padding: 28px 20px;
    }
}
//===============signup===============
.signup-top {
    margin-top: 2% !important;
}
.signup-padding {
    padding: 45px 60px;

    @media (min-width: 1440px) {
        padding: 60px 38px;
    }
    @media (max-width: 1024px) {
        padding: 15px 38px;
    }
    @media (max-width: 834px) {
        padding: 15px 18px;
    }
    @media (max-width: 576px) {
        margin: 4px 0;
        padding: 5px;
    }
    @media (max-width: 320px) {
        margin: 4px 0;
        padding: 5px;
    }
}
//===============forgot-password===============
// .forgot-password-top {
//   // margin-top: 18% !important;
// }
.forgot-password-padding {
    padding: 40px;

    @media (min-width: 1440px) {
        padding: 70px 40px;
    }
    @media (max-width: 1024px) {
        padding: 35px 38px;
    }
    @media (max-width: 834px) {
        padding: 35px 18px;
    }
    @media (max-width: 576px) {
        margin: 20px 0;
        padding: 28px 20px;
    }
    @media (max-width: 320px) {
        margin: 4px 0;
        padding: 5px;
    }
}
//===============email-verification===============
// .password-verification-top {
//   margin-top: 18% !important;
// }
.password-verification-padding {
    padding: 40px;
    @media (min-width: 1440px) {
        padding: 70px 40px;
    }
    @media (max-width: 1024px) {
        padding: 35px 38px;
    }
    @media (max-width: 768px) {
        padding: 35px 18px;
    }
    @media (max-width: 576px) {
        margin: 30px 0;
        padding: 28px 20px;
    }
}
//===============reset-password===============
// .reset-password-top {
//   margin-top: 18% !important;
// }
.reset-password-padding {
    @extend .password-verification-padding;
}
.term-condition-text {
    font-size: 14px;
}
