.rating-card-div {
    background: #474747;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
    .user-info-div {
        .profile-image {
            img {
                border-radius: 50px;
                width: 50px;
                height: 50px;
            }

            .avtar-image {
                margin-top: 1px;
            }
        }
        .user-name {
            .use-title {
                font-family: "Avenir Next";
                color: #ffffff;
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 0;
                width: 120px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @media (max-width: 1024px) {
                    width: 160px;
                }
                @media (min-width: 768px) and (max-width: 834px) {
                    width: auto;
                }
                @media (max-width: 576px) {
                    font-size: 14px;
                    width: 100%;
                }
            }

            .use-date {
                @extend .user-name;
                font-size: 14px;
                color: #ffffff;
            }
        }
        .google-icon-image{
            img{
                width: 30px;
                height: auto;
                @media (max-width: 575px){
                    width: 20px;
                }
            }
        }
    }
    .content {
        font-family: "Avenir Next";
        color: #ffffff;
        p {
            // height: 96px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }
    .view-moreBtn {
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
        &.v-hide {
            visibility: hidden;
        }
    }
}
